import { Layout } from 'antd';
import {
  AppstoreOutlined, TeamOutlined,
  CalendarOutlined,
  ClusterOutlined, CreditCardOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import Logo from './Logo';
import {
  CUSTOMER_PREFIX_PATH,
  DASHBOARD_PREFIX_PATH,
  ORDERS_PREFIX_PATH,
  PRODUCT_PREFIX_PATH,
  CURRENCY_PREFIX_PATH,
} from "configs/AppConfig";
import { RootState } from 'redux/types/Root';
import { connect } from 'react-redux';

const { Sider } = Layout;

interface SidebarProps {
  showSidebar: boolean;
}

const Sidebar = ({showSidebar}: SidebarProps) => {
  const location = useLocation();

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={showSidebar}
      className='left-slider-wrapper'
      width={250}
    >
      <Logo collapsed={showSidebar}/>
      <div className="mt-4 left-sider-nav-container">
        <div className="left-sider-nav-wrapper">
          <div className="mt-3">
            <ul className="left-sider-nav-item">
              <li className={`${location?.pathname === DASHBOARD_PREFIX_PATH ? "active" : ""}`}>
                <Link to={`${DASHBOARD_PREFIX_PATH}`}>
                  <div className='d-flex align-items-center'>
                    <AppstoreOutlined className='left-sider-nav-icon' />
                    {!showSidebar && <span className='ms-2'>Overview</span>}
                  </div>
                </Link>
              </li>
              <li className={`${location?.pathname === ORDERS_PREFIX_PATH ? "active" : ""}`}>
                <Link to={`${ORDERS_PREFIX_PATH}`}>
                  <div className='d-flex align-items-center'>
                    <CalendarOutlined className='left-sider-nav-icon' />
                    {!showSidebar && <span className='ms-2'>Orders</span>}
                  </div>
                </Link>
              </li>
              <li className={`${location?.pathname === PRODUCT_PREFIX_PATH ? "active" : ""}`}>
                <Link to={`${PRODUCT_PREFIX_PATH}`}>
                  <div className='d-flex align-items-center'>
                    <ClusterOutlined className='left-sider-nav-icon' />
                    {!showSidebar && <span className='ms-2'>Products</span>}
                  </div>
                </Link>
              </li>
              <li className={`${location?.pathname === CURRENCY_PREFIX_PATH ? "active" : ""}`}>
                <Link to={`${CURRENCY_PREFIX_PATH}`}>
                  <div className='d-flex align-items-center'>
                    <CreditCardOutlined className='left-sider-nav-icon' />
                    {!showSidebar && <span className='ms-2'>Currencies</span>}
                  </div>
                </Link>
              </li>
              <li className={`${location?.pathname === CUSTOMER_PREFIX_PATH ? "active" : ""}`}>
                <Link to={`${CUSTOMER_PREFIX_PATH}`}>
                  <div className='d-flex align-items-center'>
                    <TeamOutlined className='left-sider-nav-icon' />
                    {!showSidebar && <span className='ms-2'>Customers</span>}
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Sider>
  );
};

const mapStateToProps = ({auth}: RootState) => {
  const { showSidebar } = auth;
  return { showSidebar };
};

export default connect(mapStateToProps)(Sidebar);