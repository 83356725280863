import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loading from 'components/Loading';

const Currency = lazy(() => import(`./currency`));
const Create = lazy(() => import(`./currency/create`));
const Update = lazy(() => import(`./currency/update`));

export const CurrencyViews = () => {
  return (
    <div className="bg-background">
      <Suspense fallback={<Loading cover="page"/>}>
        <Routes>
          <Route path="/" element={<Currency title="Currencies"/>} />
          <Route path="/:id" element={<Update title="Update Currency"/>} />
          <Route path="/create" element={<Create title="Create Currency"/>} />
        </Routes>
      </Suspense>
    </div>
  )
}

export default CurrencyViews;

