import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loading from 'components/Loading';

const Category = lazy(() => import(`./order`));

export const CategoryViews = () => {
  return (
    <div className="bg-background">
      <Suspense fallback={<Loading cover="page"/>}>
        <Routes>
          <Route path="/" element={<Category title="Orders"/>} />
        </Routes>
      </Suspense>
    </div>
  )
}

export default CategoryViews;

